import InlineEditor from "./inline-edit";
export class BWEdit {
    constructor() {
        this.cmsUrl = 'https://bandwerkplus.nl';

        // Stores CKEditor editorobjects
        this.editors = [];

        // Stores the editor's value return from the editor.getData() method.
        this.oldFields = [];

        // List of fieldnames to use the 'editor' property from the this.options object.
        this.editorFields = ['inhoud', 'bericht', 'cv'];

        // Object of predefined options for CKEditor.
        this.options = {
            editor: {
                toolbar: [ 'Heading', 'Link', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'alignment' ]
            },
            text: {
                toolbar: []
            }
        };

        // Call the init method.
        this.init();
    };

    /**
     * @function: formats a string to prevent injection
     * @Warning: CLIENT SIDE ONLY
     */
    htmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }


    /**
     * @function: Checks if data has changed and prepares data for AJAX request.
     */
    async saveData() {
        if(await this.validateHash() !== true) {
            this.logout();
            return;
        }

        if(!confirm("Weet u zeker dat u deze wijzigingen wilt opslaan?")) {
            return;
        }

        // Loop through all editor object.
        this.editors.forEach((editor, index) => {

            // Check if the field has changed from when it was initiated.
            if(editor.getData() !== this.oldFields[index]) {

                const domObject = editor.sourceElement;

                const bwEdit = this.htmlEntities(domObject.getAttribute('bw-edit')),
                    bwEditField = this.htmlEntities(domObject.getAttribute('bw-edit-field')),
                    bwEditModule = this.htmlEntities(domObject.getAttribute('bw-edit-module')),
                    bwEditId = this.htmlEntities(domObject.getAttribute('bw-edit-id'));

                if(bwEdit == 'table' && bwEditField && bwEditModule && bwEditId) {
                    const data = {
                        bwEditHash: sessionStorage.getItem("inlineAuth"),
                        bwEdit,
                        bwEditField,
                        bwEditModule,
                        bwEditId,
                        bwData: editor.getData()
                    };

                    // Prepare for AJAX call.
                    const xhr = new XMLHttpRequest();

                    xhr.open('POST', this.cmsUrl + '/modules/inline/edit.php');
                    xhr.setRequestHeader("Content-Type", "text/json");
                    xhr.onload = function() {
                        if (xhr.status === 200) {
                            console.log("Saved")
                        }
                    };
                    xhr.send( JSON.stringify(data) );
                }
            }
        });

        document.querySelectorAll("[bw-edit=image][bw-image-uploaded=true] + [type=file]")
            .forEach((field) => {

                const bwEditConfig = this.htmlEntities(field.getAttribute('bw-edit-config')),
                    bwEditAlbum = this.htmlEntities(field.getAttribute('bw-edit-album-id')),
                    bwEditReplaceId = this.htmlEntities(field.getAttribute('bw-edit-replace-id'));

                // Extract files form obj.
                const files = field.files;
                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.append('files[]', file);
                }

                if(bwEditConfig && bwEditAlbum && formData) {
                    formData.append('bwEditHash', sessionStorage.getItem("inlineAuth"));
                    formData.append('bwEditConfig', bwEditConfig);
                    formData.append('bwEditAlbum', bwEditAlbum);
                    formData.append('bwEditReplaceId', bwEditReplaceId);

                    fetch(this.cmsUrl + '/modules/inline/image.php', {
                        method: 'POST',
                        body: formData,
                    }).then(response => {
                        console.log("saved");
                    })
                }
            });

        document.querySelectorAll("input[bw-edit=constant][type=text]")
            .forEach((field) => {
                const value = field.value;

                if(value !== field.getAttribute('oldvalue')) {
                    const bwEditAttr = this.htmlEntities(field.getAttribute('bw-edit-attr')),
                        bwEdit = this.htmlEntities(field.getAttribute('bw-edit'));

                    if(bwEdit == 'constant' && bwEditAttr && value) {
                        const data = {
                            bwEditHash: sessionStorage.getItem("inlineAuth"),
                            bwEditAttr,
                            bwEdit,
                            value: this.htmlEntities(value)
                        };

                        // Prepare for AJAX call
                        const xhr = new XMLHttpRequest();

                        xhr.open('POST', this.cmsUrl + '/modules/inline/constant.php');
                        xhr.setRequestHeader("Content-Type", "text/json");
                        xhr.onload = function() {
                            if (xhr.status === 200) {
                                console.log("Saved")
                            }
                        };
                        xhr.send( JSON.stringify(data) );
                    }
                }


            });

    }

    /**
     * @function: Checks if the hash is valud.
     */
    validateHash() {
        return new Promise(resolve => {
            if(sessionStorage.getItem("inlineAuth") !== undefined) {
                const data = {
                    bwEditHash: sessionStorage.getItem("inlineAuth")
                };

                // Prepare for AJAX call
                const xhr = new XMLHttpRequest();

                xhr.open('POST', this.cmsUrl + '/modules/inline/validate.php');
                xhr.setRequestHeader("Content-Type", "text/json");
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        if(xhr.response == 1) {
                            resolve(true);
                        } else {
                            alert("De sessie is verlopen. Log opnieuw in via Bandwerkplus.");
                            resolve(false);
                        }
                    }
                    else if (xhr.status !== 200) {
                        alert("De sessie is verlopen. Log opnieuw in via Bandwerkplus.");
                        resolve(false);
                    }
                };
                xhr.send( JSON.stringify(data) );
            } else {
                resolve(false);
            }
        });
    }

    /**
     * @function: Checks if the hash is valud.
     */
    reauthorize() {
        return new Promise(resolve => {
            if(sessionStorage.getItem("inlineAuth") !== undefined) {
                const data = {
                    bwEditHash: sessionStorage.getItem("inlineAuth")
                };

                // Prepare for AJAX call
                const xhr = new XMLHttpRequest();

                xhr.open('POST', this.cmsUrl + '/modules/inline/reauthorize.php');
                xhr.setRequestHeader("Content-Type", "text/json");
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        if(xhr.response == 1) {
                            resolve(true);
                        } else {
                            alert("De sessie is verlopen. Log opnieuw in via Bandwerkplus.");
                            this.logout();
                            resolve(false);
                        }
                    }
                    else if (xhr.status !== 200) {
                        resolve(false);
                    }
                };
                xhr.send( JSON.stringify(data) );
            } else {
                resolve(false);
            }
        });
    }

    /**
     * @function: Generates a button on the page to save the user changes.
     */
    async generateSaveButton() {
        if(await this.validateHash() !== true) {
            this.logout();
            return;
        }

        const toolbar = document.createElement("div");
        toolbar.classList = 'bw-edit-toolbar';

        const container = document.createElement("div");
        container.classList.add(...['container', 'd-flex']);

        const saveButton = document.createElement("button");
        saveButton.classList.add(...['bw-fab', 'ml-2']);
        saveButton.innerText = 'Opslaan';

        // Bind the saveData method to the buttonclick.
        saveButton.addEventListener('click', () => this.saveData());

        const logoutButton = document.createElement("button");
        logoutButton.classList.add(...['bw-fab', 'bw-fab--blue', 'ml-2']);
        logoutButton.innerText = 'Uitloggen';

        // Bind the saveData method to the buttonclick.
        logoutButton.addEventListener('click', () => this.logout());

        const cancelButton = document.createElement("button");
        cancelButton.classList.add(...['bw-fab', 'bw-fab--blue', 'ml-2']);
        cancelButton.innerText = 'Annuleren';

        // Bind the saveData method to the buttonclick.
        cancelButton.addEventListener('click', () => this.reload());


        container.appendChild(saveButton);
        container.appendChild(cancelButton);
        container.appendChild(logoutButton);
        toolbar.appendChild(container);

        document.body.appendChild(toolbar);

        // Position footer above content.
        document.body.style.marginBottom = parseInt(window.getComputedStyle(document.body)["marginBottom"], 10) + 53 + 'px';
        const footer = document.querySelector('footer');
        footer.style.marginBottom = parseInt(window.getComputedStyle(footer)["marginBottom"], 10) + 53 + 'px';

        this.reauthorize();
    }

    /**
     * @function: Initiates the editors.
     */
    async init() {
        if(await this.validateHash() !== true) {
            this.logout();
            return;
        }
        // Holds a boolean to check whether there are editable objects or not.
        let editors = false;

        // Select all objects on the page where bw-edit attribute is 'table'
        document.querySelectorAll( '[bw-edit=table]' )
            // Loop through all matching objects.
            .forEach((field) => {
                editors = true;

                // Create an InlineEditor from the CKEditor.
                InlineEditor
                    .create( field,
                        // Select options based on the bw-edit-field type
                        (this.editorFields.includes(field.getAttribute('bw-edit-field')) ? this.options.editor : this.options.text)
                    )
                    .then((editor) => {
                        // Add the created editorobject to the list of editors.
                        this.editors.push(editor);
                        console.log("hoi!");
                        console.log(Array.from( editor.ui.componentFactory.names() ));

                        // Add the content of the editor before it has been changed to the oldfields object.
                        this.oldFields.push(editor.getData());
                    })
                    .catch( error => {
                        console.error( error );
                    } );
            });

        // Select all editbale constants
        // Select all objects on the page where bw-edit attribute is 'table'
        document.querySelectorAll( '[bw-edit=constant]' )
            // Loop through all matching objects.
            .forEach((field) => {
                editors = true;

                const inputField = document.createElement("input");
                inputField.setAttribute("type", "text");
                inputField.setAttribute("bw-edit", field.getAttribute("bw-edit"));
                inputField.setAttribute("bw-edit-attr", field.getAttribute("bw-edit-attr"));
                inputField.setAttribute("value", field.innerHTML);
                inputField.setAttribute("oldvalue", field.innerHTML);
                inputField.style.width = parseInt(field.offsetWidth, 10) * 1.75 + "px";

                field.removeAttribute("bw-edit");
                field.removeAttribute("bw-edit-attr");

                field.innerHTML = "";
                field.appendChild(inputField);
            });

        // Select all images on the page that have the bw-edit attribute
        document.querySelectorAll( '[bw-edit=image]' )
            .forEach((field) => {
                if (field.clientHeight != 0) {
                    field.style.height = field.clientHeight + "px";
                }
                field.style.width = field.clientWidth + "px";
                field.style.objectFit = 'cover';
                field.style.objectPosition = 'top center';


                const fileUpload = document.createElement("input");
                fileUpload.setAttribute("type", "file");
                fileUpload.setAttribute("bw-edit-config", field.getAttribute("bw-edit-config"));
                fileUpload.setAttribute("bw-edit-album-id", field.getAttribute("bw-edit-album-id"));
                fileUpload.setAttribute("bw-edit-replace-id", field.getAttribute("bw-edit-replace-id"));
                fileUpload.classList.add("bw-file");
                field.insertAdjacentElement("afterend", fileUpload);

                fileUpload.addEventListener("change", () => {
                    field.src = URL.createObjectURL(fileUpload.files[0]);
                    field.setAttribute("bw-image-uploaded", true);
                })
            })

        //If there are editors, generate the savebutton.
        if(editors) this.generateSaveButton();
    };

    logout() {
        sessionStorage.removeItem("inlineAuth");

        location.replace(location.protocol + '//' + location.host + location.pathname);
    }

    reload() {
        location.reload();
    }
}
if(sessionStorage.getItem("inlineAuth")) {
    new BWEdit();
}